<template>
  <div>
    <b-modal id="modal-2" title="Tambah Permintaan Dana" ok-disabled no-close-on-backdrop hide-footer size="lg">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-form-group label="Judul Pengajuan " label-for="Judul Pengajuan">
            <validation-provider #default="{ errors }" rules="required" name="Judul Pengajuan">
              <b-form-input v-model="input.judul" :state="errors.length > 0 ? false : null"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Kode Akun Pengajuan " label-for="Akun Pengajuan">
            <validation-provider #default="{ errors }" rules="required" name="Kode Akun Pengajuan">
              <v-select v-model="input.id_kode_5" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="uraian"
                :state="errors.length > 0 ? false : null" :options="kode_akun" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Jenis Pembayaran" label-for="Jenis Pembayaran">
            <validation-provider #default="{ errors }" rules="required" name="Jenis Pembayaran">
              <b-form-select v-model="input.jenis_pembayaran" :options="jenis_pembayaran" size="sm"
                :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Bulan Pengajuan" label-for="Bulan Anggaran">
            <validation-provider #default="{ errors }" rules="required" name="Bulan Anggaran">
              <b-form-select v-model="input.bulan" :options="bulan" size="sm" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Tanggal Pengajuan" label-for="Jenis Pembayaran">
            <validation-provider #default="{ errors }" rules="required" name="Jenis Pembayaran">
              <flat-pickr v-model="input.tanggal_pengajuan" class="form-control"
                :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Nominal Pengajuan Rp. " label-for="Jumlah Pengajuann">
            <validation-provider #default="{ errors }" rules="required" name="Jumlah Pengajuan">
              <currency-input v-model="input.anggaran" :state="errors.length > 0 ? false : null"
                class="form-control"></currency-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="keterangan" label-for="">
            <b-form-textarea v-model="input.keterangan"></b-form-textarea>
          </b-form-group>
          <hr>
          <b-button variant="primary" type="submit" @click.prevent="validationForm">
            <feather-icon icon="SaveIcon" size='15' /> Submit
          </b-button>
          <b-button variant="warning" type="submit" @click.prevent="validationForm" @click="close()" class="ml-1">
            <feather-icon icon="XIcon" size='15' /> Keluar
          </b-button>

        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required
} from '@validations'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BCard, BFormSelect, BFormGroup, BFormTextarea, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CurrencyInput from '@/views/input/data_kontrak/componet/CurrencyInput.vue'

export default {
  components: {
    BButton, ValidationProvider, ValidationObserver, flatPickr,
    BModal, BFormSelect, BFormGroup, BFormTextarea, BFormInput,
    BAlert, BRow, BCol, BCard, vSelect, CurrencyInput
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      input: {}, dateDefault: '',
      jenis_pembayaran: [
        { value: 'SP2D-TU', text: 'SP2D-TU' },
        { value: 'SP2D-LS', text: 'SP2D-LS' },
        { value: 'SP2D-TU NIHIL', text: 'SP2D-TU NIHIL' },
        { value: 'PENGEMBALIAN', text: 'PENGEMBALIAN' },
        { value: 'SP2D-GU', text: 'SP2D-GU' },
        { value: 'SP2D-UP', text: 'SP2D-UP' },
      ],
      bulan: [
        { value: '1', text: 'Januari' },
        { value: '2', text: 'Februari' },
        { value: '3', text: 'Maret' },
        { value: '4', text: 'Aprl' },
        { value: '5', text: 'Mei' },
        { value: '6', text: 'Juni' },
        { value: '7', text: 'Juli' },
        { value: '8', text: 'Agustus' },
        { value: '9', text: 'September' },
        { value: '10', text: 'Oktober' },
        { value: '11', text: 'November' },
        { value: '12', text: 'Desember' },
      ],
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["SimpanRincian"],
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('SimpanRincian', this.input)
          this.close();
        }
      })
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-2');
      this.input = { jenis_pembayaran: '', id_kode_5: '', bulan: '', tanggal_pengajuan: '', anggaran: '', status: '', judul: '' }
    }
  },
  props: {
    kode_akun: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>