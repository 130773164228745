<template>
  <div>

    <!-- <b-row>
      <b-col>
        <h3 class="text-primary text-bold"><b-avatar variant="light-primary" size="35" rounded="">
            <b-avatar variant="light-primary" size="25" rounded=""><feather-icon icon="GridIcon" size='15' /></b-avatar>
          </b-avatar> <strong>{{ pagu.kode_dinas }} - {{
            pagu.nama_dinas }}</strong></h3>
      </b-col>
    </b-row> -->
    <b-row>
      <b-col md="2"><small>Pagu Anggaran </small>
        <h5><strong><sup>Rp. </sup>{{ Rp(pagu.pagu_anggaran) }}</strong></h5>
      </b-col>
      <b-col md="2">
        <small>Total Belanja Objek </small>
        <h5><strong><sup>Rp. </sup>{{ Rp(pagu.anggaran) }}</strong></h5>
      </b-col>
      <b-col md="2">
        <small>Realisasi Periode Lalu </small>
        <h5><strong><sup>Rp. </sup>{{ Rp(pagu.periode_lalu) }}</strong></h5>

      </b-col>
      <b-col md="2">
        <small>Realisai Periode Ini </small>
        <h5><strong><sup>Rp. </sup>{{ Rp(pagu.periode_ini) }}</strong></h5>
      </b-col>
      <b-col md="2">
        <small>Total Realisasi </small>
        <h5><strong><sup>Rp. </sup>{{ Rp(pagu.total_ralisali) }}</strong></h5>
      </b-col>
      <b-col md="2">
        <small>Sisa Anggaran </small>
        <h5><strong><sup>Rp. </sup>{{ Rp(pagu.sisa_anggaraan) }}</strong></h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BCard, BCol, BRow, BAvatar
} from 'bootstrap-vue'
export default {
  components: {
    BTabs,
    BTab, BCard, BCol, BRow, BAvatar
  },
  props: {
    pagu: {}
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    async xxx() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>