<template>
  <div>
    <b-row class="match-height">
      <b-col md="12">
        <b-card>
          <b-row>
            <b-col md="1" style="margin-top: 10px; text-align: right;"><strong class="mt-3">SKPD</strong></b-col>
            <b-col md="11">
              <b-form-group>
                <v-select v-model="dinas" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_dinas"
                  @input="LoadPagu()" placeholder="Silahkan pilih SKPD sesuai aksen anda" :options="rs_dinas" />
              </b-form-group>
            </b-col>
          </b-row>
          <detail_dinas :pagu="pagu" v-show="pagu != ''" style="margin-bottom: -15px;">
          </detail_dinas>
        </b-card>

      </b-col>
    </b-row>
    <b-row style="margin-top: -15px;" v-if="objek_belanja == ''">
      <b-col>
        <b-card> ** Siahkan pilih SKPD unt masuk ke tahap pengajuan anggran</b-card>
      </b-col>
    </b-row>

    <b-row style="margin-top: -15px;" v-if="objek_belanja != ''">
      <b-col>
        <b-card><daftar-objek-belanja :objek_belanja="objek_belanja"
            @LoadDetail="LoadDetail"></daftar-objek-belanja></b-card>
      </b-col>
    </b-row>
    <add_pengajuan_dana :rs_detail=rs_detail :rincian_belanja="rincian_belanja" @HapusData="HapusData">
    </add_pengajuan_dana>
    <pengajuan-dana-add :jenis_pembayaran="jenis_pembayaran" :kode_akun="kode_akun"
      @SimpanRincian=SimpanRincian></pengajuan-dana-add>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import detail_dinas from './component/detailDinas.vue'
import {
  BTabs, BRow, BCol, BCard, BTab, BFormGroup
} from 'bootstrap-vue'
import DaftarObjekBelanja from './component/DaftarObjekBelanja.vue'
import add_pengajuan_dana from './component/PengajuanDana.vue'
import PengajuanDanaAdd from './component/PengajuanDanaAdd.vue'
export default {
  components: {
    BTabs, BRow, BCol, BCard, BTab, vSelect, detail_dinas, BFormGroup,
    DaftarObjekBelanja,
    add_pengajuan_dana,
    PengajuanDanaAdd
  },
  props: {

  },
  data() {
    return {
      rs_dinas: [],
      dinas: {},
      pagu: "",
      objek_belanja: [],
      id_pagu_belanja: '',
      id_master_dinas: '',
      rs_detail: {},
      id_objek_belanja: "",
      rincian_belanja: [],

      kode_akun: []
    }
  },
  mixins: [Base],
  mounted() {
    this.load_dinas()

  },
  methods: {
    async detail_objek() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/realisasi/pengajuan_dana/detail_objek',
        data: {
          id_pagu_belanja: self.id_pagu_belanja,
          id_master_dinas: self.id_master_dinas,
          id_objek_belanja: self.id_objek_belanja
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_detail = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async SimpanRincian(input) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/realisasi/pengajuan_dana/insert',
        data: {
          id_objek_belanja: self.id_objek_belanja,
          id_pagu_belanja: self.id_pagu_belanja,
          id_master_dinas: self.id_master_dinas,
          jenis_pembayaran: input.jenis_pembayaran,
          id_kode_5: input.id_kode_5.id_kode_5,
          bulan: input.bulan,
          tanggal_pengajuan: input.tanggal_pengajuan,
          anggaran: input.anggaran,
          judul: input.judul,
          keterangan: input.keterangan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', " ", response.data.message);
          self.load_dinas()
          self.load_rincian_belanja();
          self.LoadPagu();
          self.detail_objek();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_akun() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/realisasi/pengajuan_dana/load_akun',
        data: {
          id_kode_4: self.rs_detail.id_kode_4
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.kode_akun = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async HapusData(val) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/realisasi/pengajuan_dana/hapus',
        data: {
          id_realisasi: val.id_realisasi
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', " ", response.data.message);
          self.rs = response.data.result;
          self.load_dinas()
          self.load_rincian_belanja();
          self.LoadPagu();
          self.detail_objek();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', " ", err.message);
        });
    },
    LoadDetail(val) {
      this.rs_detail = val
      this.id_objek_belanja = val.id_objek_belanja;
      this.load_rincian_belanja();
      this.load_akun();
    },
    async load_rincian_belanja() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/realisasi/pengajuan_dana/load_rincian_belanja',
        data: {
          id_objek_belanja: self.id_objek_belanja
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rincian_belanja = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async LoadPagu() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/realisasi/pengajuan_dana/pagu_anggaran',
        data: {
          id_master_dinas: self.dinas.id_master_dinas
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.pagu = response.data.result;
          self.id_pagu_belanja = response.data.result.id_pagu_belanja
          self.id_master_dinas = response.data.result.id_master_dinas
          self.laod_objek();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async laod_objek() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/realisasi/pengajuan_dana/laod_objek',
        data: {
          id_pagu_belanja: self.id_pagu_belanja,
          id_master_dinas: self.id_master_dinas
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.objek_belanja = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_dinas() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/realisasi/pengajuan_dana/load_dinas',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_dinas = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>